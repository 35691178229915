.vcard {
  max-width: 370px;
  float: left;
  border: solid 1px #cccccc;
  padding: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.vcard h3 {
  margin-top: 0px;
  text-transform: capitalize;
}
.vcard .email {
  -ms-word-break: break-word;
  word-break: break-word;
}
.vcard i {
  width: 20px;
  margin-right: 3px;
}
.vcard .fn,
.vcard .org {
  font-weight: 700;
}
.phone-icon {
  padding-left: 3px;
  padding-top: 2px;
}
.email-icon {
  font-size: 12px;
  padding-left: 3px;
}
.address-icon {
  float: left;
  padding-left: 2px;
  padding-top: 2px;
}
.edit-address,
.remove-address,
.ask a,
.confirm-yes a,
.confirm-no a {
  padding: 4px 10px;
}
.confirm-no a,
.ask a.remove-address {
  pointer-events: none;
}
.ask {
  overflow: hidden;
  width: 100%;
  float: left;
  cursor: pointer;
}
.confirm-yes,
.confirm-no {
  overflow: hidden;
  width: 0;
  float: left;
  cursor: pointer;
}
.confirm-yes button,
.confirm-yes a {
  border-radius: 4px 0px 0px 4px;
}
.confirm-no button,
.confirm-no a {
  border-radius: 0px 4px 4px 0px;
}
.slider-confirmation-container {
  overflow: hidden;
}
.slider-confirmation-container div {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.expire-date {
  padding-top: 4px;
}
.expire-date.date-is-expired {
  color: #ff0000;
}
#new-payment-method-container .form-group {
  margin: 5px;
}
.edit-expiration {
  padding: 0 !important;
  margin: 0 !important;
}
.expiration-date {
  float: left;
  padding-right: 10px;
  margin-top: 3px;
}
.credit-card.ccard {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  border: solid 1px black;
  max-width: 420px;
  width: 350px;
  margin-right: 20px;
  float: left;
}
.credit-card.ccard h3 {
  margin-top: 0;
  text-transform: capitalize;
}
.confirm-no,
.confirm-yes,
.ask,
.slider-confirmation-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.zone-navigation .widget-search-form {
  float: left;
  width: 18%;
  margin-top: 18px;
}
.address-form {
  clear: both;
}
.card-holder {
  overflow: hidden;
}
.card-contents {
  display: inline;
}
.custom-amount {
  max-width: 150px;
}
.address-modal .modal-dialog {
  max-width: 1000px;
  width: auto;
}
.address-modal .modal-dialog .modal-body {
  padding-top: 0;
}
.address-modal .modal-dialog .modal-body label.checkbox input {
  margin-right: 5px;
}
.address-modal .modal-dialog .modal-body .selectable-address-group,
.address-modal .modal-dialog .modal-body .add-address-container {
  margin: 0 45px;
}
.address-modal .modal-dialog .modal-body .selectable-address-group:before,
.address-modal .modal-dialog .modal-body .add-address-container:before,
.address-modal .modal-dialog .modal-body .selectable-address-group:after,
.address-modal .modal-dialog .modal-body .add-address-container:after {
  content: " ";
  display: table;
}
.address-modal .modal-dialog .modal-body .selectable-address-group:after,
.address-modal .modal-dialog .modal-body .add-address-container:after {
  clear: both;
}
.address-modal .modal-dialog .modal-body .add-address-container .btn.submit-new-address {
  width: 100%;
}
.address-modal .modal-dialog .modal-body .add-address-container legend {
  display: none;
}
.span-11 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
.span-10 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
.span-9 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .span-11 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
  .span-10 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
  .span-9 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label {
    float: left;
    width: 25%;
  }
}
.span-11 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
.span-10 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
.span-9 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .span-11 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
  .span-10 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
  .span-9 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control {
    float: left;
    width: 75%;
  }
}
.span-8 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
.span-7 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
.span-6 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
.span-5 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
.span-4 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .span-8 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
  .span-7 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
  .span-6 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
  .span-5 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
  .span-4 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label {
    float: left;
    width: 33.33333333%;
  }
}
.span-8 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
.span-7 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
.span-6 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
.span-5 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
.span-4 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .span-8 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
  .span-7 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
  .span-6 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
  .span-5 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
  .span-4 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control {
    float: left;
    width: 66.66666667%;
  }
}
.span-3 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
.span-2 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label,
.span-1 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-label {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
}
.span-3 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
.span-2 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control,
.span-1 .inline-address-form fieldset.address-form .horizontal-form-group .horizontal-form-control {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.address-block {
  overflow: hidden;
  display: flex;
}
